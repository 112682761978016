<template>
   <div class="cont">
      <Breadcrumb :crumbs="crumbs"/>
      <div class="content-box">
         <el-row class="search-box">
            <el-row class="search-row">
               <el-row class="search-item">
                  <label>朋友圈名称：</label>
                  <el-input v-model="params.name" placeholder="朋友圈名称" clearable></el-input>
               </el-row>
               <el-button @click="handleQuery(true)" class="bg-gradient" type="primary" icon="el-icon-search">搜索</el-button>
               <el-button @click="handleQuery(false)" icon="el-icon-refresh-right" plain>重置</el-button>
            </el-row>
         </el-row>
         <el-row class="table-box">
            <el-row type="flex" justify="space-between">
               <el-row></el-row>
               <el-row class="right-btn">
                  <el-button @click="createTask" class="bg-gradient" type="primary">创建任务</el-button>
               </el-row>
            </el-row>
            <el-table v-loading="loading" :data="tableData" style="width: 100%" :stripe="true" border fit>
               <el-table-column label="序号" width="60">
                  <template slot-scope="scope">
                     {{scope.$index + 1}}
                  </template>
               </el-table-column>
               <el-table-column prop="name" label="朋友圈名称" min-width="70"></el-table-column>
               <el-table-column label="类型" min-width="70">
                 <template>
                   全部
                 </template>
               </el-table-column>
               <el-table-column prop="content" label="内容" min-width="70"></el-table-column>
               <el-table-column prop="userName" label="提醒员工"></el-table-column>
               <el-table-column prop="sendTime" label="发送时间"></el-table-column>
               <el-table-column label="操作">
                  <template slot-scope="scope">
                     <el-button @click="handleSend(scope.row.id)" type="text">
                       <el-link type="primary">发送</el-link>
                     </el-button>
                  </template>
               </el-table-column>
            </el-table>
            <!-- 分页 -->
            <pagination :total="total" :page-size="limit" @handleSizeChangeSub="changePagNum" @handleCurrentChangeSub="changeCurrPage"/>
         </el-row>
      </div>
   </div>
</template>
<script>
import { getPage, sendCircle } from "@/api/data/wechat/customer/moments";
import { mapState } from 'vuex'
export default {
  name: 'liveCode',
  data() {
    return {
      crumbs: new Map([
        ['数据中台'], ['企业微信'], ['客户管理'], ['朋友圈群发']
      ]),
      tableData: [],
      loading: true,
      total: 0,
      limit: 0,
      page: 1,
      params: {
        name: '',
      }
    }
  },
  computed: { ...mapState(['hotelInfo']) },
  mounted() {
    this.limit = sessionStorage.getItem('pageSize') * 1
    this.getMoments()
  },
  methods: {
    // 获取朋友圈列表
    getMoments() {
      const query = `?limit=${ this.limit }&page=${ this.page }`,
            params = { ...this.params, hotelId: this.hotelInfo.id, companyId: this.hotelInfo.storeId }
      getPage(query, params).then(({ success, records, total }) => {
        if (!success) return
        this.loading = false
        this.total = total
        this.tableData = records
      })
    },
    // 改变每页数
    changePagNum(num) {
      this.limit = num
      this.getMoments()
    },
    // 改变当前页
    changeCurrPage(num) {
      this.page = num
      this.getMoments()
    },
    // 处理查询
    handleQuery(bool) {
      if (bool) return this.getMoments()
      this.params.name = ''
    },
    // 创建任务
    createTask() {
      this.$router.push({ path: '/moments_mass/create' })
    },
    // 处理发送
    handleSend(id) {
      this.$confirm('确定发送？', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        sendCircle({ id }).then(({ success }) => {
          if (!success) return
          this.getMoments()
          this.$message({ message: '发送成功！', type: 'success' })
        })
      })
    }
  },
  watch: {
  },
  filters: {
  }
}
</script>
<style lang="scss" scoped></style>
