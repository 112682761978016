import request from "@/api/service";

const api = {
   page: '/event/enterpriseWechat/circlePush/getPage',
   add: '/event/enterpriseWechat/circlePush/add',
   send: '/event/enterpriseWechat/circlePush/sendCircle'
}

export function getPage(query, params) {
   return request({
      url: api.page + query,
      method: 'POST',
      data: params
   })
}

export function createMoment(params) {
   return request({
      url: api.add,
      method: 'POST',
      data: params
   })
}

export function sendCircle(params) {
   return request({
      url: api.send,
      method: 'POST',
      data: params
   })
}
